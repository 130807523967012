<template>
  <div class="baoshibaoxiu">
    <div class="panel">
      <div class="form-item">
        <div class="weui-cell weui-cell_select">
          <div class="weui-cell__bd">
            <select class="weui-select" name="select2" v-model="area">
              <option v-for="item in areaList" :key="item.value" :value="item.value">
                {{ item.text }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div v-if="isPrivate" class="form-item">
        <div class="weui-cell weui-cell_select">
          <div class="weui-cell__bd">
            <select class="weui-select" name="select3" v-model="roomId">
              <option v-for="item in roomList" :key="item.roomId" :value="item.roomId">
                {{ item.roomName }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div v-else class="form-item">
        <mt-field placeholder="公共区域地址" v-model="addr"></mt-field>
      </div>
    </div>
    <div class="panel">
      <div class="form-item">
        <mt-field placeholder="请描述问题" type="textarea" rows="4" v-model="content"></mt-field>
      </div>
      <div class="form-item">
        <mt-field placeholder="请输入您的联系电话" type="tel" v-model="mobile" :disabled="disabledMobile"></mt-field>
      </div>
      <div class="form-item">
        <upload-imgs ref="uploadimgs" />
      </div>
      <div class="form-item form-btn-container">
        <mt-button
          @click.native.prevent="submit"
          type="primary"
          size="large"
          class="btn-yijianfankui"
          :disabled="btnDisabled"
        >提交</mt-button>
      </div>
    </div>
  </div>
</template>

<script>
import UploadImgs from '../../components/UploadImgs'
import { fetchPersonalRooms, postForm } from '../../api/api.js'

export default {
  data () {
    return {
      areaList: [
        {
          value: 'PRIVATE',
          text: '个人区域'
        },
        {
          value: 'PUBLIC',
          text: '公共区域'
        }
      ],
      area: 'PRIVATE',
      addr: '',
      roomList: [],
      roomId: '',
      content: '',
      mobile: '',
      disabledMobile: false,
      type: 'ADVICE',
      btnDisabled: false
    }
  },
  computed: {
    isPrivate () {
      return this.area === 'PRIVATE'
    },
    roomName () {
      for (let i = 0; i < this.roomList.length; i++) {
        if (this.roomList[i].roomId === this.roomId) {
          return this.roomList[i].roomName
        }
      }
      return ''
    }
  },
  components: {
    UploadImgs
  },
  created () {
    // 获取用户默认手机号
    if (!this.$store.state.userPhone) {
      this.$store.dispatch('fetchUserInfo')
        .then(res => {
          this.mobile = res.data.mobilePhone
          this.disabledMobile = true
        })
        .catch(err => {
          console.log(err.message)
        })
    } else {
      this.mobile = this.$store.state.userPhone
      this.disabledMobile = true
    }
  },
  methods: {
    openPicker () {
      this.dateTimePickerVal = new Date()
      this.$refs.picker.open()
    },
    datetimePickerHandler (value) {
      this.dateTime = value.toLocaleString()
      console.log(this.dateTimePickerVal)
    },
    async submit () {
      // console.log(this.$refs.uploadimgs.imgObjsArr)
      if (this.area === 'PUBLIC' && this.addr === '') {
        this.$toast('请填写公共区域地址')
        return
      }
      if (this.content === '') {
        this.$toast('请填写描述问题')
        return
      }
      if (!this.mobile || this.mobile.length !== 11) {
        this.$toast('请填写正确的手机号码')
        return
      }
      const imgObjsArr = this.$refs.uploadimgs.imgObjsArr
      if (imgObjsArr.some(item => !item.ret)) {
        this.$toast('存在上传失败的图片，请重新上传或删除')
        return
      }
      this.btnDisabled = true
      this.$indicator.open()
      const imageIds = imgObjsArr.map(item => item.ret.id)
      let postData = {
        area: this.area,
        content: this.content,
        mobile: this.mobile,
        type: this.type,
        imageIds
      }
      if (this.area === 'PRIVATE') {
        postData.roomId = this.roomId
        postData.areaName = this.roomName
      } else {
        postData.areaName = this.addr
      }
      try {
        await postForm(postData)
        this.$router.push({ path: 'work' })
      } catch (err) {
        this.$toast(err)
      }
      this.$indicator.close()
      this.btnDisabled = false
    },
    async fetchRooms () {
      this.$indicator.open()
      try {
        const res = await fetchPersonalRooms()
        this.roomList = res.data
        if(localStorage.getItem("chooseRoomId") == null) {
          this.roomId = this.roomList[0] && this.roomList[0].roomId
        } else {
          this.roomId = localStorage.getItem("chooseRoomId");
        }
      } catch (err) {
        console.log(err)
      }
      this.$indicator.close()
    }
  },
  mounted () {
    this.fetchRooms()
    this.$refs.uploadimgs.initUploader()
  }
}
</script>

<style scoped>
.panel {
  background: #fff;
  margin-bottom: 10px;
}

.form-btn-container {
  padding: 20px;
}

.btn-yijianfankui {
  background: orchid;
}
</style>
